import styled from 'styled-components';
import { color, font } from '../../constants/theme';
import MEDIA from '../../helpers/mediaTemplates';
import { ContentWrapper as ContentWrapperBase } from '../../global.css';
import Img from 'gatsby-image';
import StoreIconsBase from '../storeIcons';

export const Container = styled.div`
  width: 100%;
  background-color: ${color.greedDark};
  color: ${color.textInverted};
  padding: 50px 0 0;

  ${MEDIA.MIN_TABLET`
    padding-top: 40px;
  `};
`;

export const ImageWrapper = styled.div`
  position: relative;
  flex-shrink: 0;
  margin-left: auto;
  margin-right: auto;
  display: block;
  font-size: 0;
  align-self: flex-end;
  text-align: center;
  width: 322px;

  ${MEDIA.MIN_TABLET`
    margin-right: -35px;
  `};
`;

export const Image = styled(Img)`
  position: relative;
  width: 100%;
`;

export const Title = styled.h2`
  text-align: left;
  font-size: 48px;
  line-height: 48px;
  font-family: ${font.secondary};
  text-transform: uppercase;

  ${MEDIA.MIN_TABLET`
    font-size: 60px;
    line-height: 65px;
  `};
`;

export const Subtitle = styled.p`
  margin-left: auto;
  margin-right: auto;
  font-size: 16px;
  line-height: 20px;
  text-align: left;
  text-transform: uppercase;
  margin-bottom: 15px;
  letter-spacing: 0.1em;
  ${MEDIA.MIN_TABLET`
    line-height: 20px;
  `};
`;

export const StoreIcons = styled(StoreIconsBase)`
  margin-top: 26px;
  margin-bottom: 50px;
`;

export const Content = styled.div`
  position: relative;
  width: 100%;
`;

export const ContentWrapper = styled(ContentWrapperBase)`
  ${MEDIA.MIN_TABLET`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  `};
`;
