import styled from 'styled-components';
import { color, font } from '../../constants/theme';
import MEDIA from '../../helpers/mediaTemplates';
import LogoSvg from '../../images/hello-logo.svg';
import Img from 'gatsby-image';

export const Container = styled.div``;

export const Content = styled.div`
  color: ${color.textInverted};
  padding: 40px 20px;
  background: ${color.pink};
  text-align: center;
  margin-bottom: 90px;

  ${MEDIA.MIN_TABLET`
    margin-bottom: 120px;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 56px;
    padding-bottom: 62px;
  `};
`;

export const Image = styled(Img)`
  position: relative;
  border-style: solid;
  border-radius: 50%;
  border-width: 8px;
  border-color: ${color.pinkLight};
  width: 160px;
  height: 160px;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;

  ${MEDIA.MIN_TABLET`
    width: 246px;
    height: 246px;
  `};
`;

export const Logo = styled(LogoSvg)`
  position: relative;
  width: 40px;
  height: 40px;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
`;

export const Title = styled.h2`
  position: relative;
  text-align: center;
  font-size: 40px;
  line-height: 40px;
  font-family: ${font.secondary};
  text-transform: uppercase;

  ${MEDIA.MIN_TABLET`
    font-size: 60px;
    line-height: 72px;
  `};
`;

export const Text = styled.article`
  position: relative;
  max-width: 585px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  font-size: 18px;
  line-height: 28px;
  text-align: center;

  p {
    margin-bottom: 30px;
  }

  ${MEDIA.MIN_TABLET`
    margin-top: 20px;
  `};
`;
