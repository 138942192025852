import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import {
  Container,
  Subtitle,
  Title,
  Image,
  ImageWrapper,
  StoreIcons,
  Content,
  ContentWrapper,
} from './storeCtaSection.css';

const query = graphql`
  query StoreCtaSectionQuery {
    homeJson {
      storeCta {
        title
        subtitle
        appstoreLink
        playstoreLink
        image {
          childImageSharp {
            fluid(maxWidth: 700, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;

const StoreCtaSection = () => {
  const data = useStaticQuery(query).homeJson.storeCta;

  return (
    <Container>
      <ContentWrapper>
        <Content>
          <Subtitle>{data.subtitle}</Subtitle>
          <Title>{data.title}</Title>
          <StoreIcons />
        </Content>
        <ImageWrapper>
          <Image fluid={data.image.childImageSharp.fluid} />
        </ImageWrapper>
      </ContentWrapper>
    </Container>
  );
};

export default StoreCtaSection;
