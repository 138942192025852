import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { ContentWrapper } from '../../global.css';
import {
  Container,
  Title,
  Text,
  AppsHeader,
  PhoneWrapper,
  PhoneCase,
  Content,
  TextWrapper,
  Subtitle,
  Video,
  VideoWrapper,
} from './heroSection.css';
import StoreIcons from '../storeIcons';
import heroVideoSrc from '../../video/hero.mp4';

const query = graphql`
  query HeroSectionQuery {
    homeJson {
      hero {
        title
        subtitle
        joinTheClub
        text
      }
    }
  }
`;

const HeroSection = () => {
  const data = useStaticQuery(query).homeJson.hero;

  return (
    <Container>
      <ContentWrapper padding={30}>
        <Content>
          <TextWrapper>
            <Subtitle>{data.subtitle}</Subtitle>
            <Title>{data.title}</Title>
            <Text>{data.text}</Text>
            <AppsHeader>{data.joinTheClub}</AppsHeader>
            <StoreIcons />
          </TextWrapper>
          <PhoneWrapper>
            <VideoWrapper>
              <Video playsInline autoPlay muted loop>
                <source src={heroVideoSrc} type="video/mp4" />
              </Video>
            </VideoWrapper>
            <PhoneCase />
          </PhoneWrapper>
        </Content>
      </ContentWrapper>
    </Container>
  );
};

export default HeroSection;
