import styled from 'styled-components';
import { color, font } from '../../constants/theme';
import MEDIA from '../../helpers/mediaTemplates';
import Img from 'gatsby-image';

export const Container = styled.div`
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  color: ${color.black};
  padding: 100px 0 70px;
  text-align: center;

  ${MEDIA.MIN_TABLET`
    padding-bottom: 90px;
  `};
`;

export const Content = styled.div`
  position: relative;
`;

export const Title = styled.h2`
  padding-left: 20px;
  padding-right: 20px;
  font-size: 48px;
  line-height: 50px;
  font-family: ${font.secondary};

  ${MEDIA.MIN_TABLET`
    font-size: 70px;
    line-height: 70px;
  `};
`;

export const Subtitle = styled.p`
  font-size: 16px;
  line-height: 24px;
`;

export const Features = styled.div`
  ${MEDIA.MIN_TABLET`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 100px;
  `};
`;

export const Feature = styled.div``;

export const FeatureTitle = styled.h3`
  margin-bottom: 5px;
  font-size: 24px;
  line-height: 36px;

  ${MEDIA.MIN_TABLET`
    margin-bottom: 20px;
  `};
`;

export const FeatureText = styled.article`
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0;

  em,
  strong {
    font-weight: bolder;
  }

  a {
    color: ${color.pink};
  }

  ${MEDIA.MIN_TABLET`
    padding-left: 20px;
    padding-right: 20px;
  `};
`;

export const FeatureImageContainer = styled.div`
  position: relative;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;

  ${MEDIA.MIN_TABLET`
    margin-top: 40px;
    padding-bottom: 62.7%;
  `};
`;

export const FeatureImageRatioKeeper = styled.div`
  ${MEDIA.MIN_TABLET`
    width: 100%;
    height: 100%;
    position: absolute;
    align-items: flex-end;
    display: flex;
    overflow: hidden;
  `};
`;

export const FeatureImage = styled(Img)`
  width: 100%;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;

  ${MEDIA.MIN_TABLET`
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: none;
    margin-left: 0;
    margin-right: 0;
  `};
`;
