import styled from 'styled-components';
import { color, font } from '../../constants/theme';
import MEDIA from '../../helpers/mediaTemplates';
import PhoneFrameSvg from '../../images/frame.svg';

export const Container = styled.div`
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  color: ${color.black};
  padding-top: 128px;
  padding-bottom: 83px;
  background: ${color.pink};
  text-align: left;
  background: linear-gradient(180deg, #fff34b 0%, #fcd5e5 100%);

  ${MEDIA.MIN_TABLET`
    padding-top: 200px;
  `};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  ${MEDIA.MIN_TABLET`
    flex-direction: row;
    align-items: center;
    justify-content: center;
  `};
`;

export const TextWrapper = styled.div`
  ${MEDIA.MIN_TABLET`
    padding-right: 40px;
  `};
`;

export const PhoneWrapper = styled.div`
  position: relative;
  width: auto;
  margin-left: auto;
  margin-right: auto;
  margin-top: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 0;

  ${MEDIA.MIN_TABLET`
    width: auto;
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
  `};
`;

export const Subtitle = styled.h2`
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  margin-bottom: 20px;
  letter-spacing: 0.1em;
`;

export const Title = styled.h1`
  font-family: ${font.secondary};
  font-size: 48px;
  line-height: 48px;
  width: 100%;
  text-transform: uppercase;
  white-space: pre-wrap;

  ${MEDIA.MIN_TABLET`
    font-size: 70px;
    line-height: 70px;
  `};
`;

export const Text = styled.p`
  margin-top: 10px;
  font-size: 16px;
  line-height: 24px;

  ${MEDIA.MIN_TABLET`
    font-size: 18px;
    line-height: 28px;
  `};
`;

export const AppsHeader = styled.h2`
  margin-top: 30px;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 11px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
`;

export const PhoneCase = styled(PhoneFrameSvg)`
  position: relative;
  width: 337px;
  height: 495px;
  pointer-events: none;

  ${MEDIA.MIN_TABLET`
    width: 307px;
    height: 450px;
  `};
`;

export const VideoWrapper = styled.div`
  position: absolute;
  top: 3%;
  bottom: 3%;
  left: 18%;
  right: 18%;
  overflow: hidden;
  border-radius: 20px;
`;

export const Video = styled.video``;
