import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { ContentWrapper } from '../../global.css';
import {
  Container,
  Text,
  Title,
  Image,
  Logo,
  Content,
} from './helloLoverSection.css';

const query = graphql`
  query HelloLoverSectionQuery {
    homeJson {
      helloLover {
        title
        image {
          childImageSharp {
            fixed(width: 246, height: 246, quality: 100) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
        content {
          childMarkdownRemark {
            html
            rawMarkdownBody
          }
        }
      }
    }
  }
`;

const HelloLoverSection = () => {
  const data = useStaticQuery(query).homeJson.helloLover;

  return (
    <Container>
      <ContentWrapper padding={20}>
        <Content>
          <Image fixed={data.image.childImageSharp.fixed} />
          <Title>{data.title}</Title>
          <Text
            dangerouslySetInnerHTML={{
              __html: data.content.childMarkdownRemark.html,
            }}
          />
          <Logo />
        </Content>
      </ContentWrapper>
    </Container>
  );
};

export default HelloLoverSection;
