import React from 'react';
import Layout from 'components/layout';
import Hero from '../components/heroSection';
import HelloLover from '../components/helloLoverSection';
import StoreCta from '../components/storeCtaSection';
import AppFeatures from '../components/appFeaturesSection';

const Index = () => (
  <Layout>
    <Hero />
    <AppFeatures />
    <HelloLover />
    <StoreCta />
  </Layout>
);

export default Index;
