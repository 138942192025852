import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { ContentWrapper } from '../../global.css';
import {
  Content,
  Container,
  Subtitle,
  Title,
  Feature,
  FeatureImage,
  FeatureText,
  FeatureTitle,
  Features,
  FeatureImageContainer,
  FeatureImageRatioKeeper,
} from './appFeaturesSection.css';

const query = graphql`
  query AppFeaturesSectionQuery {
    homeJson {
      appFeatures {
        title
        subtitle
        items {
          title
          content {
            childMarkdownRemark {
              html
            }
          }
          image {
            childImageSharp {
              fluid(maxWidth: 856, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;

const AppFeaturesSection = () => {
  const data = useStaticQuery(query).homeJson.appFeatures;

  return (
    <Container>
      <ContentWrapper padding={30}>
        <Content>
          <Title>{data.title}</Title>
          <Subtitle>{data.subtitle}</Subtitle>
          <Features>
            {data.items.map((item, index) => (
              <Feature key={index}>
                <FeatureImageContainer>
                  <FeatureImageRatioKeeper>
                    <FeatureImage fluid={item.image.childImageSharp.fluid} />
                  </FeatureImageRatioKeeper>
                </FeatureImageContainer>
                <FeatureTitle>{item.title}</FeatureTitle>
                <FeatureText
                  dangerouslySetInnerHTML={{
                    __html: item.content.childMarkdownRemark.html,
                  }}
                />
              </Feature>
            ))}
          </Features>
        </Content>
      </ContentWrapper>
    </Container>
  );
};

export default AppFeaturesSection;
